.no-border {
  border: 0 !important;
}

.primary-card {
  background-color: $blue;
  border-left: 4px solid $primary;
  padding: 15px;
}

.k-button {
  &.add-new-btn {
    background-color: transparent;
    border-color: transparent;
    color: $text-primary;
    font-size: 14px;
  }

  &::before {
    display: none;
  }

  &:focus {
    &::before {
      display: none;
    }
  }

  &.k-focus {
    &::before {
      display: none;
    }
  }
}

.top-header-navigation {
  @media screen and (max-width: 768px) {
    .k-dropdown-button .k-button {
      padding: 0;
    }
  }
}

.social-profile-text {
  height: 30px !important;
  width: 30px !important;
  border-radius: 46px;
  position: relative;
  background: unset;
  padding: 0;
  flex-basis: auto;
  border: none;
}

.q-content {
  &-header-title {
    font-weight: 500;
    line-height: 27px;
  }
}

.blue-label {
  background-color: $label-bg;
  border-radius: 20px;
  padding: 6px 10px;
  font-size: 12px;
  color: $text-primary;
  height: 100%;
  line-height: 14px;
  display: flex;
  align-items: center;
  max-height: fit-content;
}

.k-checkbox {
  &.billable-checkbox {
    background-color: $white;
    border-color: transparent;
    color: $text-primary;
    height: 20px;
    width: 20px;
    border: 0;
    border-radius: 50px;
    background-image: url('../images/check.svg');

    &:checked {
      background-image: url('../images/checked.svg');
    }
  }
}

.text-gray {
  color: #909eb2;
}

.text-black {
  color: #404a5f !important;
}

.word-break {
  word-break: break-word;
}

.w-250 {
  width: 250px;
}

.line-height-inherit {
  line-height: inherit;
}

.vertical-align-middle {
  vertical-align: middle;
}

.p-10 {
  padding: 10px !important;
}

.font-size-13 {
  font-size: 13px;
}

.p-20 {
  padding: 20px;
}

// circular progressbar

.k-circular-progressbar {
  &-scale {
    stroke: $gray-400;
  }

  circle {
    stroke-width: 4px;
  }
}

.border-bottom-dashed {
  border-bottom: 2px dashed $gray-400;
}

// progressbar
.progress-bar {
  width: 100%;
  background-color: #f0f0f0;
  justify-content: start;
  height: 16px;
  border-radius: 4px;
  position: relative;

  .k-progressbar-value {
    background-color: #87d37c;
    border-radius: 4px 0 0 4px;

    &.k-complete {
      border-radius: 4px;
    }

    .k-progress-status {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      color: $text-primary;
      font-size: 11px;
    }
  }

  .k-progressbar-value {
    .k-progress-status-wrap {
      .k-progress-status {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }

  .k-progress-status-wrap {
    .k-progress-status {
      display: none;
    }
  }
}

// flat icon container
.flat-icon-container {
  border: 1px solid $border-color;
  padding: 15px 20px;
  color: $text-primary;
  font-size: 14px;
  background-color: $white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
}

.k-treeview-leaf {
  padding-inline: 8px;
}

//editor
.k-editor {
  border-radius: 6px;

  &-toolbar {
    box-shadow: none;
    height: auto;
    margin: 1px;

    .k-button {
      background: $white;
      border: none;
      border-radius: 0;

      &:hover {
        border-radius: 4px !important;
      }

      &.k-selected {
        color: $primary;
        background-color: $blue-200;
        &::before {
          opacity: 1;
          border-radius: 0px;
          border: 0;
          color: $primary;
          background-color: $blue-200;
        }
      }
    }

    .k-toolbar-button-group {
      cursor: not-allowed;
      .k-icon-button {
        &.k-disabled {
          svg {
            fill: #9e9e9e;
            opacity: 0.7;
          }
        }
      }
    }
  }

  .k-dialog-actions {
    .k-button {
      &:nth-child(2) {
        color: #404a5f;
        background-color: #faf8f8;
        border-color: #003bbe;
      }
    }
  }

  .k-dropdownlist {
    background-color: $white;

    .k-input-inner {
      padding-block: 6px;
    }
  }
}

.border-right {
  border-right: 1px solid $border-color;
}

.checkbox-column {
  display: flex;
  height: 36px;
  align-items: center;
}

.create-project-treeview {
  border-bottom: 1px solid $border-color;
  .k-table-row {
    .k-header {
      line-height: 16px;

      .k-checkbox {
        margin-left: 5px;
      }
    }
    .k-table-th:first-child {
      text-align: right;
    }

    .data-column {
      pointer-events: none;
    }
  }
  .k-grid-content {
    max-height: 200px;
  }
}

.assignee-tree {
  .k-tabstrip-content {
    padding-block: 15px;
  }
}

.status-container {
  display: inline-block;
  border-radius: 50px;
  padding: 0px 15px;

  &.priority {
    &-Low {
      color: $icon-green-color;
      background: $icon-green-bg;
      .indicator {
        background-color: $icon-green-color;
      }
    }
    &-None {
      color: $text-primary;
      background: $gray-500;
      .indicator {
        background-color: $text-primary;
      }
    }
    &-High {
      background-color: $icon-red-bg;
      color: $icon-red-color;
      .indicator {
        background-color: $icon-red-color;
      }
    }
    &-Medium {
      color: $icon-yellow-color;
      background: $icon-yellow-bg;
      .indicator {
        background-color: $icon-yellow-color;
      }
    }
  }
  .indicator {
    height: 7px;
    width: 7px;
    display: inline-block;
    border-radius: 50px;
  }
}

.task-status-container {
  display: inline-block;
  border-radius: 50px;
  padding: 0px;
  height: 25px;
  width: 25px;
  text-align: center;
  font-size: 12px;

  &.priority {
    &-Low {
      color: $icon-green-color;
      background: $icon-green-bg;
      .indicator {
        background-color: $icon-green-color;
      }
    }
    &-None {
      color: $text-primary;
      background: $gray-500;
      .indicator {
        background-color: $text-primary;
      }
    }
    &-High {
      background-color: $icon-red-bg;
      color: $icon-red-color;
      .indicator {
        background-color: $icon-red-color;
      }
    }
    &-Medium {
      color: $icon-yellow-color;
      background: $icon-yellow-bg;
      .indicator {
        background-color: $icon-yellow-color;
      }
    }
  }

  .status-section {
    display: inline-block;
    margin-top: 5px;
  }
}

.dashboard {
  .k-grid {
    .k-table-td,
    .k-table-th {
      border-width: 1px;
    }
    .k-table-row {
      &:last-child {
        .k-table-td {
          border-bottom: 1px solid $border-color;
        }
      }
      .k-table-td {
        border-bottom: 1px solid $border-color;
        border-top: 0;
        &:last-child {
          border-right: 0;
        }
      }
    }
    .k-table-th {
      &:last-child {
        border-right: 0;
      }
    }
  }
}

//Timer window
.start-timer {
  &.k-window {
    box-shadow: none;
    background-color: $blue;
    border-left: 4px solid $primary;
    border-radius: 0;
    min-width: 450px !important;
    z-index: 10000 !important;

    .timer {
      padding: 2px 7px;
      height: 24px;
      width: 24px;
      border-radius: 50px;

      .k-button-text {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
      }

      &-stop {
        color: #d9d9d9;
      }

      &-pause,
      &-play {
        background-color: transparent;
        color: $primary;
      }
    }

    &:hover {
      background-color: #e2effa;
    }
  }

  .k-window-titlebar {
    padding-block: 10px;
  }

  .k-window-content {
    padding-top: 0;
  }
}
// Add Project
.kendo-editor {
  height: 150px;
  min-height: 150px !important;
  max-height: 800px !important;
  overflow: auto;
  &.vertical-resize-only {
    width: 100% !important;
    max-width: 100% !important;
  }
  .k-editor-content {
    margin-top: 8px;
  }
  .k-editor-toolbar {
    order: 2;
    &.k-toolbar-resizable {
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }
}

.k-avatar-text {
  color: $white;
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
}

.k-dialog {
  height: auto !important;
  max-width: calc(100vw - 15vw);
}

.k-drawer-content {
  overflow: auto;
}

.add-goal-grid {
  .k-grid-content {
    max-height: 200px;
    margin-bottom: 5px;
    .k-table-row {
      .k-grid-save-command {
        margin-right: 0;
      }
      .k-command-cell {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 45px;
      }
    }
  }
}

.timelog-grid {
  .k-grid-content {
    max-height: 350px;
  }
  .k-grid-aria-root {
    overflow: auto !important;
  }
}

.permission-modal {
  .k-dialog-content {
    max-height: calc(100vh - 30px);
    .permission-content {
      max-height: 300px;
      min-height: 300px;
      overflow: auto;
    }
  }
}

.timepicker-text-field {
  .k-button-solid-base {
    display: none;
  }
}

// Grid custom No records container
.custom-no-records-container {
  .k-grid-norecords {
    .k-table-td {
      padding: 0;
      border: 0;
      .no-records-text-container {
        padding-block: 9px;
        padding-inline: 9px;
        border-bottom: 1px solid $gray-400;
      }
    }
  }
  .k-grid-header-table {
    .k-table-row {
      .k-header {
        .k-cell-inner {
          .k-sort-order {
            display: none;
          }
        }
      }
    }
  }
}

.tag-grid,
.add-goal-grid,
.add-sub-task-grid {
  .k-grid-norecords {
    display: none;
  }
  .k-grid-toolbar {
    &::before {
      content: none;
    }
  }
  .k-grid-header {
    border: 0;
  }
}

.add-goal-grid,
.add-sub-task-grid {
  .k-grid-table {
    .k-table-row {
      &:nth-child(odd) {
        .k-table-td {
          border-bottom: 1px solid $border-color;
        }
      }
      &:first-child {
        .k-table-td {
          border-top: 1px solid $border-color;
        }
      }
      &:last-child {
        .k-table-td {
          border-bottom: 1px solid $border-color;
        }
      }
    }
  }
  .k-table-th {
    &.k-header {
      padding: 0;
    }
  }
}

// create project popup tag action buttons
.actions-button {
  padding: 0;
  background: transparent;
  font-size: 14px;
  color: $text-primary;
  height: 35px;
  width: 35px;
  display: inline-block;
  border: none;
  opacity: 1;
  &:hover {
    background-color: #e7e7e7;
    border-radius: 50%;
  }
  &.k-disabled {
    background: transparent;
    .k-button-text {
      color: $text-primary;
    }
  }
}

.calculate-height {
  min-height: calc(100vh - 95px);
}

.grid-action-list {
  &.grid-command-action {
    .grid-command-action-links {
      color: $text-primary;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: $white;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      padding: 0;
      &:hover {
        background-color: $border-color;
      }
    }
  }
}

.grid-action-list-visible-button {
  &.grid-command-action {
    .grid-command-action-links {
      color: $text-primary;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: $white;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      padding: 0;
      font-size: 14px;
      &:hover {
        background-color: $border-color;
      }
    }
  }
}

.task-list {
  abp-validation-error {
    display: none;
  }
}

.project-logo {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background-color: $link-color;
  color: $white;
  border: 0;
  max-width: 30px;
  .k-avatar-text {
    font-size: 15px;
    font-weight: 600;
  }
}

.project-logo-time-page {
  height: 15px;
  width: 15px;
  border-radius: 3px;
  margin-bottom: 2px;
  padding-inline: 5px;
  .k-avatar-text {
    font-size: 8px;
    font-weight: 500;
  }
}

.bug-icon-color {
  color: $icon-red-color !important;
}

.task-type-dropdown {
  &.k-menu-popup {
    .k-menu-group {
      .k-menu-item {
        &.k-focus {
          background-color: transparent;
          color: unset;
        }
        .k-menu-link {
          padding: 0;
          &:hover {
            .dropdown-item-color {
              color: $primary !important;
            }
          }
          .dropdown-menu-links {
            padding-block: 8px;
            padding-inline: 16px;
            padding-inline-end: calc(32px + 16px);
            width: 114px;
            &.taskTypeSelected {
              color: $primary;
              background-color: $blue-200;
              border-radius: 5px;
              .dropdown-item-color {
                color: $primary;
                &.bug-icon-color {
                  color: $icon-red-color;
                }
              }
              &:hover {
                color: $primary;
              }
            }
            .dropdown-item-color {
              color: $text-primary;
            }
          }
        }
      }
    }
  }
}

.k-menu-popup {
  max-height: 200px;
  .k-menu-link {
    &:hover {
      background-color: $blue-200;
      color: $primary;
    }
  }
}

.more-details-popup {
  .k-dialog {
    position: fixed;
    right: 0;
    min-height: 100vh !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 550px;
    .k-dialog-content {
      max-height: calc(100vh - 159px);
    }
  }
}

.h-fit-content {
  height: fit-content;
}

.vertical-align-top {
  vertical-align: top !important;
}

kendo-drawer-container {
  &.add-more-details {
    .k-drawer {
      height: 100vh;
      position: fixed;
      right: 0px;
      border-radius: 20px 0 0 20px;
      .k-drawer-wrapper {
        width: 100% !important;
        max-width: calc(100vw - 40vw);
        overflow-y: hidden;
        padding: 0;
        @media screen and (max-width: 768px) {
          max-width: 100vw;
          margin: 0;
        }
      }
    }
    &.k-drawer-overlay {
      .k-overlay {
        z-index: 10000;
      }
      .k-drawer {
        transform: translateX(0%) !important;
        z-index: 10000;
        @media screen and (max-width: 1023px) {
          transform: translateX(0%) !important;
        }
      }
    }
  }
}

kendo-drawer-container {
  &.filter-drawer-container {
    &.k-drawer-overlay {
      .k-drawer {
        transform: translateX(0px) !important;
      }
    }
    .k-drawer {
      height: 100vh;
      position: fixed;
      right: 0px;
      border-radius: 20px 0 0 20px;
      .k-drawer-wrapper {
        width: 500px !important;
        max-width: calc(100vw - 40vw);
        overflow-y: hidden;
        padding: 0;
        margin: 0;
        @media screen and (max-width: 768px) {
          max-width: 100vw;
          margin: 0;
          width: 100% !important;
        }
      }
    }
  }
}

.add-time-log-quickly,
.attach-files-popup {
  &.k-dialog-wrapper {
    z-index: 100000;
  }
}

.add-time-log-quickly,
.add-project-details {
  .k-textbox,
  .k-datepicker,
  .k-dropdownlist,
  .k-input-solid {
    &.k-focus {
      border-color: $primary !important;
    }
    &:hover {
      border-color: $primary !important;
    }
    &.disabled {
      border-color: $gray-500 !important;
    }
  }
}

.add-time-log-quickly {
  border-radius: 20px;
  .k-window-titlebar {
    border-bottom: 1px solid $border-color;
    padding-inline: 0;
    margin-inline: 24px;
    cursor: move;
    .k-window-title {
      font-size: 18px;
      font-weight: 500;
      cursor: move;
    }
    .k-window-titlebar-actions {
      .k-button {
        &:hover {
          &::before {
            opacity: 0;
          }
        }
        .k-svg-icon {
          svg {
            fill: #7a7d9c;
          }
        }
      }
    }
  }
  .k-window-content {
    padding-block: 0;
    padding-inline: 0;
    overflow: hidden;
    .timeLog-popup-content {
      padding-inline: 24px;
      padding-block: 8px;
      font-size: 13px;
      max-height: calc(100vh - 279px);
      overflow: auto;
    }
  }
  .k-window-actions {
    justify-content: end;
    padding-right: 0;
    padding-left: 0;
    margin-left: 25px;
    margin-right: 25px;
    width: auto;
    border-top: 1px solid #e7e7e7;
  }
}

.timelog-popup {
  height: auto !important;
  .k-window-titlebar-actions {
    button[title='Minimize'],
    button[title='Maximize'],
    button[title='Restore'] {
      display: none !important;
    }
  }
}

.k-popup {
  &.k-calendar-container {
    padding: 0;
  }
}

.k-calendar {
  .k-calendar-view {
    .k-today {
      background: $gray-600;
      color: $text-primary;
      font-weight: 500;
      .k-link {
        background: $gray-600;
        color: $text-primary;
        font-weight: 500;
      }
      &:hover {
        .k-link {
          background: $gray-600;
          font-weight: 500;
        }
      }
      &.k-selected {
        &:hover {
          .k-link {
            background-color: $primary;
          }
        }
      }
    }
  }
}

.flex-0 {
  flex: 0;
}

.add-sub-task-grid {
  .k-grid-content {
    max-height: 200px;
    margin-bottom: 5px;
  }
  .k-grid-edit-row {
    background-color: #f5f7fa;
    &:hover,
    &:focus {
      background-color: #f5f7fa !important;
    }
    .k-input-inner-text-box {
      border: 0;
      border-bottom: 1px solid $border-color;
      &.ng-invalid {
        &.ng-touched {
          border-color: #f31700;
        }
      }
    }
    .grid-action-list {
      visibility: visible;
      .grid-command-action-links {
        box-shadow: #00000026 0 2px 8px;
      }
    }
    .k-command-cell {
      vertical-align: text-top;
    }
    .k-table-td {
      padding: 10px;
    }
  }
}

.timer-picker-popup {
  &.k-popup {
    max-height: 300px;
    .k-time-list-container {
      .k-time-list {
        bottom: 40px;
      }
      .k-time-list-wrapper {
        .k-title {
          background-color: $white;
          position: relative;
          z-index: 100;
        }
      }
    }
    .k-time-cancel {
      background-color: $gray-200;
      color: $text-primary;
    }
    .k-time-header {
      .k-time-now {
        color: $primary;
      }
    }
  }
}

.ngx-spinner-overlay {
  z-index: 1000000 !important;
}

.k-upload-files {
  .k-file {
    border-width: 1px !important;
    border-color: $border-color;
    border-radius: 6px;
    padding: 10px !important;
    margin-bottom: 10px;
    align-items: center !important;
    &:last-child {
      border-width: 1px !important;
    }
  }
}

.taskList-dashboard {
  .k-grid-content {
    max-height: calc(100vh - 348px);
  }
}

.task-count-popup {
  .k-dialog-titlebar {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .k-dialog-actions {
    border-top: 0;
    padding: 15px 0;
  }

  .k-grid-header {
    padding-right: 0 !important;
  }

  .k-grid-content {
    overflow-y: hidden;
  }
}

.task-name-input {
  .k-textbox {
    border: 0;
    border-bottom: 1px solid transparent;
    border-radius: 0 !important;
  }
}

.task-list-table {
  .k-grid-table {
    .k-table-td {
      padding-block: 5px;
      padding-inline: 5px;
    }
    .k-table-row {
      &:hover {
        background-color: #f5f7fa !important;
      }
    }
  }
}

.k-drawer-container {
  &.k-drawer-mini {
    .k-drawer-content {
      .task-list-table {
        .task-name {
          max-width: 740px;
          @media screen and (min-width: 1600px) {
            max-width: 850px;
          }
        }
        .task-status {
          max-width: 950px;
        }
        .task-all-fields {
          max-width: 700px;
        }
      }
    }
  }
}

.time-dashborad-grid {
  .k-table-td {
    vertical-align: text-top;
  }
  .k-table-th {
    .k-column-title {
      white-space: normal;
      line-height: 18px;
    }
  }
}

.description-section {
  border: 1px solid $border-color;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  p {
    margin: 0;
    line-height: 22px;
  }
  ol li {
    list-style: auto;
  }
  ul {
    padding-left: 2rem;
  }
  ul li {
    list-style: disc;
  }
}

.comment-body {
  font-size: 14px;
  line-height: 1.5;
  color: #404a5f;
  padding-left: 50px;
  margin-top: 3px;
  ol li {
    list-style: auto;
  }
  ul {
    padding-left: 2rem;
  }
  ul li {
    list-style: disc;
  }
}

.k-form-label {
  color: #404a5f;
  font-size: 13px;
  line-height: 2;
}

.file-container {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
}

.file-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #003bbe;
  border-radius: 50%;
  color: #ffffff;
  font-size: 20px;
  .k-avatar-text {
    font-size: 15px;
  }
}

.file-info {
  display: flex;
  flex-direction: column;
}

.file-name {
  font-size: 14px;
  color: #212529;
}

.file-details {
  font-size: 12px;
  color: #777;
}

.downloadFile-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #e7e7e7;
  color: #212529;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.project-name-header-tab {
  .k-tabstrip-items-wrapper {
    width: 100%;
    position: relative;
    z-index: 0;
  }
  .k-tabstrip-content {
    width: 100%;
    padding-bottom: 0;
    padding-top: 15px;
    overflow: visible;
    .action-panel {
      position: absolute;
      top: -56px;
      right: 0;
      @media screen and (max-width: 800px) {
        position: unset;
        margin-bottom: 15px;
      }
    }
  }
}

.task-list-table {
  .k-treeview-item {
    width: 100%;
    .k-treeview-leaf {
      width: 100%;
      &.k-focus {
        background-color: transparent;
      }
      .k-treeview-leaf-text {
        width: 100%;

        @media (max-width: 768px) {
          a.display-inline {
            span.task-title {
              max-width: 100% !important;
            }
          }
        }
      }
      &:hover {
        background-color: #f5f7fa;
      }
    }
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  .task-list-grid {
    width: 100%;
    .task-list-action {
      visibility: hidden;
      @media screen and (max-width: 768px) {
        visibility: visible;
      }
      ul {
        .grid-command-action-links {
          color: $text-primary;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background-color: $white;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          text-align: center;
          line-height: 30px;
          box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
          &:hover {
            background-color: $border-color;
          }
        }
      }
    }
    &:hover {
      .task-list-action {
        visibility: visible;
      }
    }
  }
}

.add-new-btn {
  background-color: transparent;
  border-color: transparent;
  color: $text-primary;
  font-size: 14px;
  padding-block: 13px;
  padding-inline: 23px;
}

.add-task-area {
  border: 0;
  border-bottom: 1px solid $border-color;
  border-radius: 0 !important;
}

.action-menu {
  .grid-command-action-links {
    color: $text-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: $white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    &:hover {
      background-color: $border-color;
    }
  }
}

.k-expander-content {
  padding-left: 20px;
  padding-right: 0;
}

.add-task-section,
.complete-action-menu {
  .action-menu {
    visibility: hidden;
    @media screen and (max-width: 768px) {
      visibility: visible;
    }
  }
  &:hover {
    .action-menu {
      visibility: visible;
    }
  }
}
.lightboxOverlay {
  z-index: 10000;
}

.lightbox {
  height: 100%;
  .lb-dataContainer {
    position: absolute;
    right: 15px;
    top: -55px;
  }
}

#taskList {
  .k-expander {
    & + &.k-expanded,
    &.k-expanded + & {
      margin-top: 0;
    }

    &.k-expanded {
      overflow: visible;
      &:has(.k-window.k-dialog.k-editor-window) {
        .k-expander-header {
          z-index: 0;
        }
      }

      .k-expander-header {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: $white;
      }
      .k-expander-content-wrapper {
        z-index: 0;
        position: relative;
      }
    }
  }
}

app-task-list {
  .add-more-details {
    &.k-drawer-overlay {
      .k-drawer:has(app-add-task-more-details) {
        width: 60vw;
        @media (max-width: 768px) {
          width: 100vw;
        }
      }
    }
  }
  .k-tabstrip-items-wrapper {
    .k-item {
      &:hover {
        background-color: transparent;
      }
      .k-link {
        font-size: 14px;
        line-height: 24px;
        padding-block: 15px;
        padding-inline: 20px;
        padding-top: 0;
      }
    }
    .k-tabstrip-items {
      .k-item {
        &:first-child {
          .k-link {
            padding-left: 0;
          }
        }
      }
    }
  }
  .setting-page-tab {
    .setting-grid {
      .k-grid-header {
        display: none;
      }
    }
    .k-tabstrip-items {
      .k-item {
        &:first-child {
          .k-link {
            padding-left: 20px;
          }
        }
      }
    }
    .k-grid-toolbar {
      display: flex;
      justify-content: end;
      margin-bottom: 20px;
      position: absolute;
      right: 0;
      top: -67px;
    }
    .k-tabstrip-items-wrapper {
      position: relative;
    }
  }
}

.filter-icon-container {
  border-radius: 50px;
  border: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 8px 13px;
  cursor: pointer;
}

.export-dropDown {
  border: 1px solid $border-color;
  border-radius: 50px;
  .k-dropdown-button {
    .k-button {
      color: #212529;
      font-size: 13px;
    }
  }
}

.k-pager-md .k-pager-sizes .k-dropdown-list,
.k-pager-md .k-pager-sizes .k-dropdown,
.k-pager-md .k-pager-sizes .k-dropdownlist,
.k-pager-md .k-pager-sizes > select {
  width: 6em;
}

.k-datepicker:has(.k-focus) {
  border: 1px solid $primary !important;
}

.timeLog-grid-section {
  .fix-grid-height {
    height: auto !important;
    .k-grid-content {
      max-height: 250px;
    }
  }
}

.dashboard-expasion-panel {
  .task-list-grid {
    .k-expander {
      .k-expander-header {
        border-bottom: 0px solid $border-color !important;
      }
      &.k-expanded {
        .k-expander-header {
          border-bottom: 1px solid #404a5f !important;
        }
      }
    }
  }
}

.task-complete-label {
  label {
    line-height: 14px;
  }
}
