abp-page-toolbar {
  #AbpContentToolbar {
    .btn-primary {
      background-color: $primary;
      border-radius: 5px;
      padding: 8px 12px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
  }
}

// datatable

.ngx-datatable {
  height: calc(100vh - 202px);
  &.material {
    .datatable {
      &-body {
        .datatable-body-row {
          .datatable-body-cell {
            padding: 9px 9px !important;
            border-color: $border-color;
          }

          .icon-list {
            visibility: hidden;
          }

          &:hover {
            .datatable-row-group {
              background-color: transparent !important;

              .grid-action-list {
                visibility: visible;
              }
            }

            .icon-list {
              visibility: visible;
            }
          }
        }
      }

      &-header {
        .datatable-header-cell {
          padding: 9px 9px !important;

          .sort-btn {
            color: $text-secondary;

            &:before {
              color: $text-secondary;
              font-family: 'data-table' !important;
            }
          }

          .datatable-icon-sort-unset {
            &:before {
              font-family: 'data-table' !important;
              content: 'c' !important;
            }
          }

          .datatable-icon-up {
            &:before {
              font-family: 'data-table' !important;
              content: '\65' !important;
            }
          }

          .datatable-icon-down {
            &:before {
              font-family: 'data-table' !important;
              content: '\66' !important;
            }
          }
        }
      }

      &-header-cell-template-wrap {
        color: $blue-100;
        font-size: 14px;
        line-height: 28px;
        font-weight: 500;
        text-transform: capitalize;
        letter-spacing: 0px;
      }

      &-row-center {
        .datatable-header-cell {
          &:last-child {
            .datatable-header-cell-label {
              display: none;
            }
          }
        }
      }

      &-body-cell-label {
        color: $text-primary;
      }

      &-footer {
        &-inner {
          .page-count {
            color: $blue-100;
            font-size: 14px;
            padding: 0 9px;
            font-weight: 500;
          }
        }

        .datatable-pager {
          li {
            a {
              font-size: 12px;
              min-width: 30px;
              border-radius: 5px;
              color: $text-primary !important;
              padding: 5px 1px !important;

              &:hover {
                background-color: $border-color;
              }
            }

            &.active {
              a {
                color: $text-primary;
                box-shadow: $box-shadow-100;
                background-color: transparent;

                &:hover {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }

    &:not(.cell-selection) {
      .datatable-body-row {
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }

  // Badge
  .badge {
    padding: 7px 10px;
    font-weight: 400;
    border-radius: 6px !important;
    &.bg-success {
      color: $white !important;
      background-color: $green-100 !important;
    }
    &.bg-info {
      color: $white !important;
      background-color: $blue-400 !important;
    }
  }
}

// Dialog

.ngx-pe-dialog {
  &-container {
    border-radius: $border-radius;
    background-color: $white;
  }

  &-header {
    color: $primary;
    font-size: $font-size-18;
    font-weight: 500;
    padding: 25px 0;
    margin: 0 25px;
    border-bottom: 1px solid $border-color;
  }

  &-body {
    padding: 8px 25px;
    overflow: auto;

    .ngx-pe-cropper-wrapper {
      .cropper-container {
        width: auto !important;
        border-radius: $border-radius;

        .cropper-modal {
          background-color: $blue;
          border-radius: $border-radius;
        }
      }

      .ngx-pe-tool-bar {
        background-color: $white;
        gap: 15px;
        margin-top: 15px;

        button {
          color: $text-primary;

          &:hover {
            background-color: transparent;
          }
        }

        svg {
          fill: $text-primary;
        }
      }
    }
  }

  &-footer {
    padding: 25px 0;
    margin: 0 25px;
    border-top: 1px solid $border-color;

    .ngx-pe-btn {
      font-weight: 400;
      font-size: 12px;
      border-radius: 12px;
      background-color: $primary;
      padding: 13px 23px;
      color: $white;

      &-outline-light {
        background-color: $gray-200;
        border-color: $primary;
        color: $text-primary;
      }
    }
  }
}

.form-group {
  &.mb-3 {
    margin-bottom: 21px !important;
  }
}

.form-label {
  color: $text-primary;
  font-weight: 400;
  line-height: 2;
  margin: 0;
  font-size: 13px;
}

.form-control {
  color: $text-primary;
  border-radius: $input-border-radius;
  padding: 13px 16px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  border-color: $border-color;

  &:focus,
  &:hover {
    color: $text-primary;
    background-color: transparent;
    border-color: $border-color;
    box-shadow: none;
  }
  &:disabled {
    background-color: #e9ecef;
  }
}

.form-control + .btn-secondary {
  background-color: $primary;
  border-color: $primary;

  &:hover {
    background-color: $primary;
    border-color: $primary;
  }
}

.form-check-input {
  border: 1.6px solid $border-color;
  background-color: transparent;

  &:checked {
    border-color: $primary !important;
    background-color: $primary !important;
  }
}

.form-check {
  input[type='checkbox'] {
    width: 16px;
    height: 16px;
  }
}

.input-group {
  .btn-secondary {
    color: $white;
    background-color: $primary !important;

    &:hover {
      color: $white;
    }
  }
}

// Setting Page
abp-extensible-form {
  .form-control {
    background-color: $white;
  }
  abp-extensible-form-prop {
    .mb-3 {
      margin-bottom: 20px !important;
    }
  }
}

// toast styling
.abp-toast {
  padding: 14px !important;
  border: none !important;
  margin: 0 !important;
  grid-template-columns: 23px 1fr !important;
  box-shadow: none !important;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 5px;
    display: inline-block;
  }

  &-container {
    justify-content: flex-start !important;
    top: 20px !important;
    right: 20px !important;
    z-index: 10000000 !important;
    min-height: unset !important;
    height: 50px !important;
  }

  &-message {
    font-family: $kendo-font-family;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &-title {
    display: none;
  }

  &.abp-toast-error {
    background-color: $error-bg !important;

    .abp-toast-icon {
      display: inline-block !important;

      .icon {
        &::before {
          background: url('../images/error-toast.svg');
          content: '';
          height: 25px;
          width: 25px;
          position: absolute;
          top: 12px;
          background-repeat: no-repeat;
        }
      }
    }

    &::before {
      background: $error-color;
    }

    .abp-toast-message {
      color: $error-color;
    }
  }

  &.abp-toast-success {
    background: $success-bg !important;

    .abp-toast-icon {
      display: inline-block !important;

      .icon {
        &::before {
          background: url('../images/success-toast.png');
          content: '';
          height: 25px;
          width: 25px;
          position: absolute;
          top: 12px;
          background-repeat: no-repeat;
        }
      }
    }

    &::before {
      background: $success-color;
    }

    .abp-toast-message {
      color: $success-color;
    }
  }

  &.abp-toast-info {
    background: $info-bg !important;

    .abp-toast-icon {
      display: inline-block !important;

      .icon {
        &::before {
          background: url('../images/info-toast.png');
          content: '';
          height: 25px;
          width: 25px;
          position: absolute;
          top: 12px;
          background-repeat: no-repeat;
        }
      }
    }

    &::before {
      background: $info-color;
    }

    .abp-toast-message {
      color: $info-color;
    }
  }

  &.abp-toast-warning {
    background: $warn-bg !important;

    .abp-toast-icon {
      display: inline-block !important;

      .icon {
        &::before {
          background: url('../images/warn-toast.png');
          content: '';
          height: 25px;
          width: 25px;
          position: absolute;
          top: 12px;
          background-repeat: no-repeat;
        }
      }
    }

    &::before {
      background: $warn-color;
    }

    .abp-toast-message {
      color: $warn-color;
    }
  }

  .abp-toast-close-button {
    display: none !important;
  }
}

.form-check {
  .form-check-label,
  .k-label,
  label {
    color: $text-primary;
    line-height: 20px;
    font-size: 14px;
  }
}

abp-setting-management {
  .content-header-title {
    display: none;
  }
}

abp-personal-settings-form {
  abp-extensible-form-prop {
    &.d-inline-block.w-50 {
      width: auto !important;

      .w-50 {
        width: auto !important;
      }
    }
  }
}

#SettingManagementWrapper {
  margin: 0 20px;

  .col-12 {
    width: 100%;
  }

  h2,
  hr {
    display: none;
  }
}

abp-manage-profile {
  .col-12 {
    &.col-md-3 {
      margin-bottom: 21px;
    }

    width: 100%;
  }

  .card {
    margin: 0 20px;
    box-shadow: none !important;
  }

  .nav-item {
    margin-bottom: 0px !important;
  }

  .tab-pane {
    h4 {
      display: none;

      hr {
        display: none;
      }
    }
  }
}

//Boostrap Tabing
.nav-pills {
  border-bottom: 1px solid $border-color;

  .nav-link {
    background-color: transparent;
    color: $text-secondary;
    font-weight: 400;
    border-radius: 0;
    padding: 14px 20px;
    font-size: 16px;
    line-height: 22px;
    border-bottom: 1px solid $border-color;

    &:hover {
      &:not(.active) {
        background-color: $blue;
      }
    }

    &.active {
      color: $primary;
      background-color: transparent;
      border-bottom-color: $primary;
    }
  }

  &.flex-column {
    flex-direction: row !important;
  }
}

.tab-content {
  padding: 10px;
}

// Boostrap Button

.btn,
.swal-button {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid;

  &:focus {
    box-shadow: none;
  }
}

.btn-primary,
.swal-button--confirm {
  @include button-variant($white, $primary, $primary);
}

.btn-secondary,
.swal-button--cancel {
  @include button-variant($text-primary, $gray-200, $primary);

  &.mr-3 {
    margin-right: 20px !important;
  }

  &:focus-visible,
  &:active {
    color: $text-primary;
    background-color: $gray-200;
  }
}

abp-button {
  .btn:disabled {
    border-color: #e0e0e0 !important;
    color: rgba(0, 0, 0, 0.38) !important;
    background-color: #e0e0e0 !important;
  }
}

.invalid-feedback {
  color: $danger;
}

.is-invalid {
  .form-control {
    border-color: $danger;
  }
}

.modal {
  &-header {
    border-bottom: 1px solid $border-color;
    padding-inline: 0;
    padding-block: 16px;
    margin-inline: 24px;
    h3 {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 500;
      color: $primary;
    }

    .modal-title {
      color: $primary;
      font-size: 18px;
      font-weight: 500;
    }

    .close {
      color: $text-secondary;
      opacity: 1;
    }
  }

  &-content {
    border-radius: 20px;
  }

  &-body {
    padding-block: 8px;
    padding-inline: 24px;
  }

  &-footer {
    padding: 16px 24px 16px 24px;
  }
}

// Delete Dialog

.swal {
  &-modal {
    border-radius: 20px;
  }

  &-title {
    font-size: 18px;
    font-weight: 500;
    color: $primary;
  }

  &-text {
    font-size: 14px;
    color: $text-secondary;
    font-weight: 400;
    text-align: center;
  }

  &-overlay {
    z-index: 100000;
  }
}

.custom-user-popup {
  .k-dialog {
    width: 450px;
  }
}

abp-confirmation {
  div.confirmation {
    z-index: 100001 !important;
    .confirmation-dialog {
      max-height: calc(100vh - 100px);
      overflow-y: auto;
      padding: 20px;
      max-width: 450px;
      width: 100%;
      margin: 0;
      .icon-container {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
      }
      .content {
        .message {
          line-height: 20px;
          padding-block: 0 !important;
        }
      }
    }
  }
}
