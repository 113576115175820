.q-sidebar-container {
  border-right: 1px solid $border-color;
  .q-logo-container {
    margin-bottom: 14px;
    .q-toggle-icon {
      @media screen and (min-width: 1024px) {
        display: none;
      }
      display: block;
      .toggle-icon {
        padding: 8px 12px !important;
      }
    }
    .q-brand-logo {
      padding: 13px 22px;
      height: 55px;
      img {
        width: 115px;
        margin: 0 !important;
      }
    }
  }
  .k-drawer-items {
    height: calc(100vh - 134px);
  }
}

.k-drawer-mini {
  .q-sidebar-container {
    .k-drawer-items {
      height: calc(100vh - 151px);
      @media screen and (min-width: 1024px) {
        height: calc(100vh - 162px);
      }
    }
  }
}

.k-drawer-overlay {
  &.k-drawer-mini .q-sidebar-container {
    .q-logOut-container {
      .q-sidebar-toggle {
        @media screen and (min-width: 1024px) {
          left: 90px;
        }
      }
    }
  }
}

.q-topbar-container {
  display: none;
  background-color: $white !important;
  @media screen and (max-width: 1024px) {
    display: block;
  }
  .toolbar {
    padding: 10px 20px !important;
    margin-bottom: 20px !important;
    align-items: center !important;
    border-bottom: 1px solid $border-color;
    height: auto;
    @media screen and (max-width: 577px) {
      align-items: center !important;
      flex-direction: row !important;
    }
    @media screen and (max-width: 1024px) {
      flex-direction: row !important;
      margin-bottom: 0 !important;
    }
    .title {
      @media screen and (max-width: 475px) {
        margin-left: 10px !important;
        @media screen and (max-width: 1024px) {
          font-size: 14px;
          margin-left: 15px !important;
        }
      }
    }
    .top-header-navigation-wrapper {
      @media screen and (max-width: 577px) {
        margin: 0;
      }
      @media screen and (max-width: 1024px) {
        display: none !important;
      }
    }
    .toggle-icon {
      padding: 0 !important;
      margin-top: 5px;
    }
  }
}

@media screen and (max-width: 768px) {
  .top-header-navigation-wrapper {
    margin: 0 !important;
  }
}

.k-drawer-wrapper {
  padding: 0;
  width: 250px !important;
  height: 100%;
  transition: all 100ms linear !important;
  .k-drawer-items {
    padding: 0 10px 0 10px;
    .k-drawer-item {
      margin: 0;
      .custom-drawer-item {
        display: inline-block;
        margin-bottom: 5px;
      }
    }
    .k-drawer-link {
      padding: 8px 10px;
      border-radius: 5px;
      font-size: 14px !important;
      > div {
        gap: 10px !important;
      }
    }
  }
}

.k-drawer-container {
  &.k-drawer-mini {
    .k-drawer-wrapper {
      width: 80px !important;
      margin: 0;
      .q-icon-logo {
        display: flex !important;
        justify-content: center;
        img {
          width: 28px;
        }
      }
      .k-drawer-items {
        padding: 15px;
        .k-drawer-link {
          padding: 14px 5px;
        }
      }
    }
  }
}

.k-button {
  padding: 8px 12px;
  border-radius: 5px;
  .k-button-text {
    line-height: 19px;
  }
}

.k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
  padding-block: 8px;
  padding-inline: 12px;
}

.k-input-solid,
.k-picker-solid {
  border-radius: 5px !important;
}

.k-input.k-textbox .k-input-prefix i {
  padding-block: 8px;
  padding-inline: 12px;
  padding-right: 0;
}

.k-combobox .k-button,
.k-dropdownlist .k-button,
.k-multiselect .k-button {
  padding: 8px 10px;
}

.k-grid {
  .k-grid-header {
    .k-table-th {
      padding-top: 0;

      &.k-sorted {
        .k-link {
          cursor: pointer;
        }
      }

      .k-link {
        cursor: default;
      }

      > .k-cell-inner > .k-link {
        padding-top: 5px;
      }
    }
  }
}

.grid-header-padding {
  &.k-grid {
    .k-grid-header {
      .k-table-th {
        padding-top: 9px;
      }
    }
  }
}

.k-dropdownlist-popup {
  .k-list-content {
    .k-list-item {
      &.k-focus {
        &:hover {
          background-color: $blue-200;
          color: $primary;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .k-expander .k-expander-header {
    align-items: start;
    .k-expander-indicator {
      margin-top: 8px;
    }
  }
}
