/* You can add global styles to this file, and also import other style files */
@import url('https://unpkg.com/@progress/kendo-font-icons/dist/index.css');
@import 'qtheme-lib/styles/import.scss';
@import 'qtheme-lib/styles/styles.scss';
@import 'variable';
@import '../../assets/scss/abp-override.scss';
@import '../../assets/scss/default';
@import '../scss/override-qtheme.scss';

body {
  background-color: $blue;
  height: 100%;
}

.left-side-conent {
  margin-right: 422px;
  padding: 0 30px 30px 30px;
}

@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

:root {
  --lpx-logo: url('/assets/images/logo/logo-light.png');
  --lpx-logo-icon: url('/assets/images/logo/logo-light-thumbnail.png');
}

.donut {
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7983ff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: donut-spin 1.2s linear infinite;

  &.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// top-header navigation
.top-header-navigation {
  .k-searchbar {
    .k-input-inner {
      padding: 0;
      font-size: 14px;
      color: $text-primary;
    }
  }
}
